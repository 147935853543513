<template>
  <div>
    <landing-page-header></landing-page-header>
   <div class="container mt-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/home" tag="a">{{$t('menu.home')}}</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{$t('menu.agenda')}}</li>
      </ol>
    </nav>
    <div class="row">
          <div class="col-12 pt-3">
            <h2 class="title pl-1">Agenda</h2>
          </div>
        </div>
    <div class="card">
      <div class="card-body" v-if="events.length > 0">
         
        <a-calendar v-model:value="value" @select="selectDay">
        <template #dateCellRender="{ current: value }">
         <a-tooltip>
    <template #title>{{getTooltiptData(value)}}</template>
          <ul class="events">
            <li v-if="getListData(value).length > 0">
               
               <a-badge
                :count="getListData(value).length"
                :number-style="{
                  backgroundColor: '#ce2129',
                  color: '#fff',
                  boxShadow: '0 0 0 1px #ce2129 inset',
                }"
              />
             
            </li>
          </ul>
         </a-tooltip>
        </template>
        <template #monthCellRender="{ current: value }">
          <div v-if="getMonthData(value) > 0" class="notes-month">
            <section><a-badge
                :count="getMonthData(value)"
                :number-style="{
                  backgroundColor: '#ce2129',
                  color: '#fff',
                  boxShadow: '0 0 0 1px #ce2129 inset',
                }"
              /></section>
            <span>Evenements</span>
          </div>
        </template>
      </a-calendar>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status">
          </div>
        </div>
      </div>
    </div>
    <!-- Calendar pop up -->
    <a-modal
      v-model:visible="visible"
      :width="700"
      :height="1200"
      @ok="handleOk"
      :title="$t('general.eventList')"
      :footer="null"
    >
      <eventsCard :data="dayEvents" :date="value"/>
    </a-modal>
   </div>
  </div>
</template>

<script>

import { defineComponent, ref } from 'vue';
import apiAxios from '@/services/axios'
import moment from 'moment'
import localization from 'moment/locale/fr'
import EventsCard from '@/components/EventsCard'
import LandingPageHeader from '@/components/LandingPageHeader'

export default defineComponent({
  name:'Agenda',
    components: { LandingPageHeader, EventsCard },
  setup() {
    const value = ref();

    const getMonthData = value => {
      if (value.month() === 8) {
        return 1394;
      }
    };

    return {
      value,
    };
  },
  data: () => ({

    visible: false,
    dayEvents: [],
    events: [],
    localization,
  }),
  mounted() {
   this.getEvents();
  },
  methods: {
    moment,
    handleOk: function (e) {
      this.visible = false;
    },
    selectDay: function(value){
       moment.locale('fr');
      this.visible = true;
      this.dayEvents = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-DD-YYYY") == moment(value).locale("fr", localization).format("MM-DD-YYYY")
      })
    },
    getListData: function(value) {
         let listData = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-DD-YYYY") == moment(value).locale("fr", localization).format("MM-DD-YYYY")
      })
      return listData;
    },
    getTooltiptData: function(value) {
         let listData = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-DD-YYYY") == moment(value).locale("fr", localization).format("MM-DD-YYYY")
      })
      return listData[0]?listData[0].title.fr:"";
    },
    getMonthData: function(value){
       moment.locale('fr');
      let listData = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-YYYY") == moment(value).locale("fr", localization).format("MM-YYYY")
      })
      return listData.length;
    },
    getEvents: function() {
        apiAxios
        .post('/agandas/filter',{query:{status: "active"}})
        .then(res => {
          this.events = res.data
          console.log(this.events)
        })
        .finally(() => {
          //this.tableLoading=false
        })
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
}

.card {
  border: 1px solid $primary;
  border-radius: 0px;
}

.card-body {
  padding: 0;
}
</style>

<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}


</style>